// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-promotions_JA5fI{padding:24px 8px 16px;margin:8px;background:var(--Layer1);border-radius:5px}html.app__layout--desktop .casino-promotions-button_nNrmR{display:flex;margin:24px 0 0;align-items:center;justify-content:center}html.app__layout--desktop .casino-promotions-button__item_zDG7Z{width:auto;min-width:160px;white-space:nowrap}html.app__layout--desktop .casino-promotions-button__item--primary_kxaSJ{margin:0}html.app__layout--desktop .casino-promotions-button__item--secondary_PVti3{margin-left:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-promotions": `casino-promotions_JA5fI`,
	"casino-promotions-button": `casino-promotions-button_nNrmR`,
	"casino-promotions-button__item": `casino-promotions-button__item_zDG7Z`,
	"casino-promotions-button__item--primary": `casino-promotions-button__item--primary_kxaSJ`,
	"casino-promotions-button__item--secondary": `casino-promotions-button__item--secondary_PVti3`
};
export default ___CSS_LOADER_EXPORT___;
