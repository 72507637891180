// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-game-sidebar-switcher_AbZ5k{display:flex;align-items:center;width:44px;height:32px;background-color:var(--Layer1);border-radius:50px}@media(hover:hover)and (pointer:fine){.casino-game-sidebar-switcher_AbZ5k:hover{cursor:pointer;background-color:var(--Highlight)}}.casino-game-sidebar-switcher__icon_r18qR{flex-shrink:0;margin-right:-6px;margin-left:2px}.casino-game-sidebar-switcher__level-icon_LJZNb{flex-shrink:0}.casino-game-sidebar-switcher--opened__q8pf{flex-direction:row-reverse}.casino-game-sidebar-switcher--opened__q8pf .casino-game-sidebar-switcher__icon_r18qR{margin-right:1px;margin-left:0}.casino-game-sidebar-switcher--opened__q8pf .casino-game-sidebar-switcher__level-icon_LJZNb{margin-right:-5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-game-sidebar-switcher": `casino-game-sidebar-switcher_AbZ5k`,
	"casino-game-sidebar-switcher__icon": `casino-game-sidebar-switcher__icon_r18qR`,
	"casino-game-sidebar-switcher__level-icon": `casino-game-sidebar-switcher__level-icon_LJZNb`,
	"casino-game-sidebar-switcher--opened": `casino-game-sidebar-switcher--opened__q8pf`
};
export default ___CSS_LOADER_EXPORT___;
