// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .casino-lobby-block-error_VjMqs{max-width:375px;padding:32px}html.app__layout--desktop .casino-lobby-block-error__group-name_sbAY4{color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-lobby-block-error": `casino-lobby-block-error_VjMqs`,
	"casino-lobby-block-error__group-name": `casino-lobby-block-error__group-name_sbAY4`
};
export default ___CSS_LOADER_EXPORT___;
