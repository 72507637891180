// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-game-sidebar-anonymous_APn9A{position:relative;display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%;padding:0 16px}.casino-game-sidebar-anonymous__button-close_mTKwF{position:absolute;top:0;right:0}.casino-game-sidebar-anonymous__icon_S08A1{margin-bottom:16px}.casino-game-sidebar-anonymous__heading_wg5L6,.casino-game-sidebar-anonymous__text_U1Lc6{color:var(--TextDefault);text-align:center}.casino-game-sidebar-anonymous__heading_wg5L6{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;margin-bottom:8px}.casino-game-sidebar-anonymous__text_U1Lc6{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px}.casino-game-sidebar-anonymous__button_PJj2H{margin-top:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-game-sidebar-anonymous": `casino-game-sidebar-anonymous_APn9A`,
	"casino-game-sidebar-anonymous__button-close": `casino-game-sidebar-anonymous__button-close_mTKwF`,
	"casino-game-sidebar-anonymous__icon": `casino-game-sidebar-anonymous__icon_S08A1`,
	"casino-game-sidebar-anonymous__heading": `casino-game-sidebar-anonymous__heading_wg5L6`,
	"casino-game-sidebar-anonymous__text": `casino-game-sidebar-anonymous__text_U1Lc6`,
	"casino-game-sidebar-anonymous__button": `casino-game-sidebar-anonymous__button_PJj2H`
};
export default ___CSS_LOADER_EXPORT___;
