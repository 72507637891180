import { computed, onMounted, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
export default function useBonusPointsContainer(props) {
    const bonusLoyaltyStore = useBonusLoyaltyStore();
    const siteConfigStore = useSiteConfigStore();
    const formatMoney = useFormatMoney();
    const { $translate } = useI18n();
    const router = useRouter();
    const { isLoggedIn } = useIsLoggedIn();
    const loyaltyProgramEgsStatus = toRef(siteConfigStore, 'loyaltyProgramEgsStatus');
    const bonusLoaded = toRef(bonusLoyaltyStore, 'bonusLoaded');
    const amountNumber = toRef(bonusLoyaltyStore, 'amountNumber');
    const remainingAmountNumber = toRef(bonusLoyaltyStore, 'remainingAmountNumber');
    const progress = toRef(bonusLoyaltyStore, 'progress');
    const progressPercentString = toRef(bonusLoyaltyStore, 'progressPercentString');
    const progressProperties = computed(()=>({
            percentage: progress.value > 0 ? parseInt(progressPercentString.value, 10) : 0,
            circleSize: props.isSmall ? CircleSize.SIZE_32 : CircleSize.SIZE_40
        }));
    const requiredAmount = computed(()=>formatMoney(remainingAmountNumber.value, {
            currency: 'L'
        }));
    const pointsLabelProperties = computed(()=>formatMoney(amountNumber.value || 0, {
            currency: 'L',
            hideCurrency: true
        }));
    const buttonProperties = computed(()=>({
            label: $translate('WEB2_JSPACC_ACC_EXCHANGE').value,
            kind: ButtonKind.SECONDARY,
            height: props.isSmall ? ButtonHeight.TINY : ButtonHeight.MEDIUM
        }));
    const openBonusLoyaltyPage = ()=>{
        let name = RouteName.BONUS_WITHDRAWALS;
        if (progress.value < 1) name = RouteName.LOYALTY_PROGRAM;
        router.push({
            name
        });
    };
    const handleExchange = ()=>{
        router.push({
            name: RouteName.BONUS_WITHDRAWALS
        });
    };
    onMounted(async ()=>{
        if (isLoggedIn.value) await bonusLoyaltyStore.fetchBonusLoyalty();
    });
    return {
        loyaltyProgramEgsStatus,
        bonusLoaded,
        openBonusLoyaltyPage,
        progressProperties,
        pointsLabelProperties,
        progress,
        requiredAmount,
        buttonProperties,
        handleExchange
    };
}
