// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .casino-category-list_EMbil{position:relative;display:inline-flex;flex-flow:row nowrap;justify-content:left;width:100%;overflow-x:auto;-ms-overflow-style:none}html.app__layout--desktop .casino-category-list_EMbil::-webkit-scrollbar{display:none}html.app__layout--desktop .casino-category-list__slide_foRU8{padding-left:4px}html.app__layout--desktop .casino-category-list__slide_foRU8:first-child{padding-left:0}html.app__layout--desktop .casino-category-list__slide_foRU8:last-child{padding-right:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-category-list": `casino-category-list_EMbil`,
	"casino-category-list__slide": `casino-category-list__slide_foRU8`
};
export default ___CSS_LOADER_EXPORT___;
