// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-sidebar-bonus-block_iUh9M{display:flex;align-items:center;width:100%;height:72px;padding:0 12px;margin-bottom:8px;cursor:pointer;background-color:var(--Layer1);border:none;border-radius:5px}.casino-sidebar-bonus-block_iUh9M:hover{background-color:var(--Highlight)}.casino-sidebar-bonus-block__body_seNFC{display:flex;align-items:center;justify-content:space-between;width:100%;text-decoration:none;-webkit-text-decoration:none solid currentColor;text-decoration:none solid currentColor;text-decoration-thickness:auto;-webkit-text-decoration:auto;text-decoration:auto}.casino-sidebar-bonus-block__left_mtXwI{display:flex;gap:12px;justify-content:flex-start}.casino-sidebar-bonus-block__info_1us3M{display:flex;flex-direction:column}.casino-sidebar-bonus-block__title_mu5iH{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;width:120px;padding-right:12px;margin:0;overflow:hidden;color:var(--TextDefault);text-overflow:ellipsis;white-space:nowrap}.casino-sidebar-bonus-block__label_ngtid{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal;color:var(--TextSecondary)}.casino-sidebar-bonus-block__icon_f_srJ{color:var(--TextSecondary)}.casino-sidebar-bonus-block__loader_INUbj{display:flex;gap:12px;justify-content:flex-start}.casino-sidebar-bonus-block__loader-avatar_rSbsx{display:flex;align-items:center;justify-content:center;width:40px;height:40px}.casino-sidebar-bonus-block__loader-circle_mqoXi{width:36px;height:36px;background-color:var(--Layer2);border-radius:50%}.casino-sidebar-bonus-block__loader-text_SxUT3{display:flex;flex-direction:column;gap:12px;padding:6px 0}.casino-sidebar-bonus-block__loader-line-1_wAD4o{width:84px;height:9px;background-color:var(--Layer2);border-radius:20px}.casino-sidebar-bonus-block__loader-line-2_Vodwv{width:48px;height:7px;background-color:var(--Layer2);border-radius:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-sidebar-bonus-block": `casino-sidebar-bonus-block_iUh9M`,
	"casino-sidebar-bonus-block__body": `casino-sidebar-bonus-block__body_seNFC`,
	"casino-sidebar-bonus-block__left": `casino-sidebar-bonus-block__left_mtXwI`,
	"casino-sidebar-bonus-block__info": `casino-sidebar-bonus-block__info_1us3M`,
	"casino-sidebar-bonus-block__title": `casino-sidebar-bonus-block__title_mu5iH`,
	"casino-sidebar-bonus-block__label": `casino-sidebar-bonus-block__label_ngtid`,
	"casino-sidebar-bonus-block__icon": `casino-sidebar-bonus-block__icon_f_srJ`,
	"casino-sidebar-bonus-block__loader": `casino-sidebar-bonus-block__loader_INUbj`,
	"casino-sidebar-bonus-block__loader-avatar": `casino-sidebar-bonus-block__loader-avatar_rSbsx`,
	"casino-sidebar-bonus-block__loader-circle": `casino-sidebar-bonus-block__loader-circle_mqoXi`,
	"casino-sidebar-bonus-block__loader-text": `casino-sidebar-bonus-block__loader-text_SxUT3`,
	"casino-sidebar-bonus-block__loader-line-1": `casino-sidebar-bonus-block__loader-line-1_wAD4o`,
	"casino-sidebar-bonus-block__loader-line-2": `casino-sidebar-bonus-block__loader-line-2_Vodwv`
};
export default ___CSS_LOADER_EXPORT___;
