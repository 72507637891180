// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-category-list-item_lAmp7{position:relative;display:flex;justify-content:center;width:68px;min-width:68px;height:52px;color:var(--TextPrimary);-webkit-text-decoration:none;text-decoration:none;cursor:pointer;-webkit-user-select:none;user-select:none;background-color:var(--Layer1);border-radius:5px}.casino-category-list-item_lAmp7:hover{color:var(--TextDefault);background-color:var(--Highlight)}.casino-category-list-item--active_Zg9Sq,.casino-category-list-item--active_Zg9Sq:hover{color:var(--Layer0);background-color:var(--TextDefault)}.casino-category-list-item__inner_nWSYk{display:flex;flex-direction:column;gap:0;align-items:center;padding:0;margin-top:8px}.casino-category-list-item--loading_zhTvu .casino-category-list-item__inner_nWSYk{display:none}.casino-category-list-item__inner-label_tYp9o{font-size:11px;font-weight:500;line-height:16px;letter-spacing:-.2px;width:64px;margin:4px 0;overflow:hidden;text-align:center;text-overflow:ellipsis;white-space:nowrap}.casino-category-list-item__skeleton_YbBW7{z-index:110;display:none;margin:8px 0}.casino-category-list-item--loading_zhTvu .casino-category-list-item__skeleton_YbBW7{display:flex;flex-direction:column;gap:0;align-items:center;width:auto;padding:0}.casino-category-list-item--loading_zhTvu .casino-category-list-item__skeleton-label_GDB8q{width:32px;margin:10px 0 0}.casino-category-list-item__skeleton-icon_t3tRR,.casino-category-list-item__skeleton-label_GDB8q{background-color:var(--Layer2)}.casino-category-list-item__skeleton-icon_t3tRR{width:20px;height:20px;border-radius:50%}.casino-category-list-item__skeleton-label_GDB8q{width:32px;height:6px;margin-top:10px;border-radius:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-category-list-item": `casino-category-list-item_lAmp7`,
	"casino-category-list-item--active": `casino-category-list-item--active_Zg9Sq`,
	"casino-category-list-item__inner": `casino-category-list-item__inner_nWSYk`,
	"casino-category-list-item--loading": `casino-category-list-item--loading_zhTvu`,
	"casino-category-list-item__inner-label": `casino-category-list-item__inner-label_tYp9o`,
	"casino-category-list-item__skeleton": `casino-category-list-item__skeleton_YbBW7`,
	"casino-category-list-item__skeleton-label": `casino-category-list-item__skeleton-label_GDB8q`,
	"casino-category-list-item__skeleton-icon": `casino-category-list-item__skeleton-icon_t3tRR`
};
export default ___CSS_LOADER_EXPORT___;
