// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .casino-slots-provider-page_l6Eq_{padding:0 32px 32px}html.app__layout--desktop .casino-slots-provider-page__list_Cpd8u{margin-top:16px}html.app__layout--desktop .casino-slots-provider-page__no-results_HOWfl{font-size:14px;font-weight:400;line-height:24px;letter-spacing:normal;padding:12px 0;color:var(--TextSecondary);text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-slots-provider-page": `casino-slots-provider-page_l6Eq_`,
	"casino-slots-provider-page__list": `casino-slots-provider-page__list_Cpd8u`,
	"casino-slots-provider-page__no-results": `casino-slots-provider-page__no-results_HOWfl`
};
export default ___CSS_LOADER_EXPORT___;
