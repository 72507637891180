// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-casino-slots-panel_bZwFN{padding:8px;background-color:var(--Layer1);border-radius:5px}.bonus-casino-slots-panel__title_vdZGa{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;max-width:250px;margin-bottom:16px;overflow:hidden;color:var(--TextDefault);text-overflow:ellipsis;white-space:nowrap}.bonus-casino-slots-panel__informer-wrapper_V_yvP{display:flex;flex-grow:1;align-items:center;justify-content:space-between}.bonus-casino-slots-panel__informer_VHBbA{display:inline-flex;align-items:center}.bonus-casino-slots-panel__balance-label_t551B{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal;margin-right:4px;color:var(--TextSecondary)}.bonus-casino-slots-panel__balance_SF0eg{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;margin-right:4px;color:var(--TextDefault)}.bonus-casino-slots-panel__icon_WaCHb{cursor:pointer}.bonus-casino-slots-panel__button_E3q7E{width:126px}.bonus-casino-slots-panel__progress_zWuNM{display:flex;align-items:center;margin:8px 0 12px;white-space:nowrap}.bonus-casino-slots-panel__progress-line_zlcnt{width:100%}.bonus-casino-slots-panel__info_GmjKj{display:flex;gap:8px;align-items:center;justify-content:space-between}.bonus-casino-slots-panel__info-date_OHDYx{line-height:12px}.bonus-casino-slots-panel__info-date_OHDYx,.bonus-casino-slots-panel__info-text_FjOKp{font-size:12px;font-weight:400;letter-spacing:normal;color:var(--TextSecondary)}.bonus-casino-slots-panel__info-text_FjOKp{line-height:16px}.bonus-casino-slots-panel__info-text--current_OrOxf{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextDefault)}.bonus-casino-slots-panel__info-text--divider_muJry{padding:0 3px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-casino-slots-panel": `bonus-casino-slots-panel_bZwFN`,
	"bonus-casino-slots-panel__title": `bonus-casino-slots-panel__title_vdZGa`,
	"bonus-casino-slots-panel__informer-wrapper": `bonus-casino-slots-panel__informer-wrapper_V_yvP`,
	"bonus-casino-slots-panel__informer": `bonus-casino-slots-panel__informer_VHBbA`,
	"bonus-casino-slots-panel__balance-label": `bonus-casino-slots-panel__balance-label_t551B`,
	"bonus-casino-slots-panel__balance": `bonus-casino-slots-panel__balance_SF0eg`,
	"bonus-casino-slots-panel__icon": `bonus-casino-slots-panel__icon_WaCHb`,
	"bonus-casino-slots-panel__button": `bonus-casino-slots-panel__button_E3q7E`,
	"bonus-casino-slots-panel__progress": `bonus-casino-slots-panel__progress_zWuNM`,
	"bonus-casino-slots-panel__progress-line": `bonus-casino-slots-panel__progress-line_zlcnt`,
	"bonus-casino-slots-panel__info": `bonus-casino-slots-panel__info_GmjKj`,
	"bonus-casino-slots-panel__info-date": `bonus-casino-slots-panel__info-date_OHDYx`,
	"bonus-casino-slots-panel__info-text": `bonus-casino-slots-panel__info-text_FjOKp`,
	"bonus-casino-slots-panel__info-text--current": `bonus-casino-slots-panel__info-text--current_OrOxf`,
	"bonus-casino-slots-panel__info-text--divider": `bonus-casino-slots-panel__info-text--divider_muJry`
};
export default ___CSS_LOADER_EXPORT___;
