// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-game-freespin__firework_jKFpo{width:100%;max-width:60px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-game-freespin__firework": `casino-game-freespin__firework_jKFpo`
};
export default ___CSS_LOADER_EXPORT___;
