// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .casino-game-footer_l6RoQ{display:inline-block;margin-bottom:16px;margin:0}html.app__layout--desktop .casino-game-footer__title_WI_vk{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;margin:18px 16px;color:var(--TextDefault)}html.app__layout--desktop .casino-game-footer__description_yDPev{padding:16px;background-color:var(--Layer1);border-radius:5px}html.app__layout--desktop .casino-game-footer__text_7GxKE{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal;color:var(--TextPrimary)}html.app__layout--desktop .casino-game-footer__toggle-button_MZb34{margin:0 16px;display:none}html.app__layout--desktop .casino-game-footer__table_CFGpS{display:flex;flex-wrap:wrap}@media only screen and (min-device-width:320px)and (max-device-width:430px){html.app__layout--desktop .casino-game-footer__table_CFGpS{display:grid;grid-gap:16px 2px;margin-bottom:16px}html.app__layout--desktop .casino-game-footer__table-item_f96Lu{width:auto;margin:0}}@media only screen and (min-device-width:320px)and (max-device-width:380px){html.app__layout--desktop .casino-game-footer__table_CFGpS{grid-template-columns:repeat(3,1fr)}}@media only screen and (min-device-width:381px)and (max-device-width:430px){html.app__layout--desktop .casino-game-footer__table_CFGpS{grid-template-columns:repeat(4,1fr)}}html.app__layout--desktop .casino-game-footer__table-item_f96Lu{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal;display:flex;flex-direction:column;width:97px;height:40px;margin:0 4px 16px 0;justify-content:flex-start;text-align:left}html.app__layout--desktop .casino-game-footer__table-title_OuCqE{overflow:hidden;color:var(--TextSecondary);text-overflow:ellipsis;white-space:nowrap}html.app__layout--desktop .casino-game-footer__table-text_Lz_q_{color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-game-footer": `casino-game-footer_l6RoQ`,
	"casino-game-footer__title": `casino-game-footer__title_WI_vk`,
	"casino-game-footer__description": `casino-game-footer__description_yDPev`,
	"casino-game-footer__text": `casino-game-footer__text_7GxKE`,
	"casino-game-footer__toggle-button": `casino-game-footer__toggle-button_MZb34`,
	"casino-game-footer__table": `casino-game-footer__table_CFGpS`,
	"casino-game-footer__table-item": `casino-game-footer__table-item_f96Lu`,
	"casino-game-footer__table-title": `casino-game-footer__table-title_OuCqE`,
	"casino-game-footer__table-text": `casino-game-footer__table-text_Lz_q_`
};
export default ___CSS_LOADER_EXPORT___;
