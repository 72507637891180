// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.betgames_J0uBy{min-height:100vh;overflow:hidden;border-radius:0;box-shadow:none}.betgames__iframe_mdz9F{width:100%}.betgames__loader_SFLBl{position:absolute;top:0;right:0;bottom:0;left:0}.betgames__wrapper_CZWCW{position:relative;min-height:calc(100vh - 56px)}.betgames__iframe-error_r3UHY{z-index:1;position:absolute;top:0;right:0;bottom:0;left:0;padding-top:126px;background:var(--Layer1)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"betgames": `betgames_J0uBy`,
	"betgames__iframe": `betgames__iframe_mdz9F`,
	"betgames__loader": `betgames__loader_SFLBl`,
	"betgames__wrapper": `betgames__wrapper_CZWCW`,
	"betgames__iframe-error": `betgames__iframe-error_r3UHY`
};
export default ___CSS_LOADER_EXPORT___;
