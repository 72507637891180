// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-lobby__navigation_yLx7n{padding:8px 0}.casino-lobby__no-games_GhQ2l{width:290px;padding:40px 0 80px;margin:0 auto}.casino-lobby__block-error_yW42R{margin:0 auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-lobby__navigation": `casino-lobby__navigation_yLx7n`,
	"casino-lobby__no-games": `casino-lobby__no-games_GhQ2l`,
	"casino-lobby__block-error": `casino-lobby__block-error_yW42R`
};
export default ___CSS_LOADER_EXPORT___;
