// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-sidebar-filter-block_s0iVI{padding:4px;background-color:var(--Layer1);border-radius:var(--BorderRadius)}.casino-sidebar-filter-block__search_RWyB1{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;display:flex;gap:16px;align-items:center;justify-content:flex-start;width:100%;height:44px;padding:0 16px;margin-bottom:0;color:var(--TextPrimary);cursor:pointer;background-color:none;border-radius:5px}.casino-sidebar-filter-block__search_RWyB1:hover{color:var(--TextPrimary);background-color:var(--Highlight)}.casino-sidebar-filter-block__provider_wVd3O{height:44px;padding:0 12px;border-radius:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-sidebar-filter-block": `casino-sidebar-filter-block_s0iVI`,
	"casino-sidebar-filter-block__search": `casino-sidebar-filter-block__search_RWyB1`,
	"casino-sidebar-filter-block__provider": `casino-sidebar-filter-block__provider_wVd3O`
};
export default ___CSS_LOADER_EXPORT___;
