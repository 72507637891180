// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-game-sidebar_zhb7x{position:relative;max-height:704px}.casino-game-sidebar__inner_fX3S8{flex-shrink:0;width:280px;height:100%;overflow-y:auto;background-color:var(--EgsOverlayBarColor);border-radius:0 5px 5px 0}.casino-game-sidebar__header_HCdwM{display:flex}.casino-game-sidebar__header--is-over-game_IztoR{justify-content:space-between}.casino-game-sidebar__tabs_SFSgt{flex:1;width:50%}.casino-game-sidebar__tabs--multi_vyCpt{width:100%}.casino-game-sidebar__content_i3RXp{position:absolute;top:44px;right:0;bottom:0;left:0;max-height:calc(100% - 44px);overflow-y:auto}.casino-game-sidebar__content-scroll__usR8{height:100%}.casino-game-sidebar__content-inner_v1keJ{padding:12px 16px}.casino-game-sidebar__current-level_dcWAQ{margin-bottom:8px}.casino-game-sidebar__shop-heading_tyxDz{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;margin-bottom:18px;color:var(--TextDefault)}.casino-game-sidebar__shop-wrapper_XGP7W{display:flex;flex-direction:column;gap:8px}.casino-game-sidebar--is-over-game_zbZG1{z-index:5;position:absolute;top:0;bottom:0;display:flex;justify-content:flex-end;width:calc(100vw - 40px);min-width:790px;max-width:1200px;pointer-events:none}.casino-game-sidebar--is-over-game_zbZG1 .casino-game-sidebar__inner_fX3S8{pointer-events:all;background-color:var(--OpacityLayer1);-webkit-backdrop-filter:blur(40px);backdrop-filter:blur(40px);border-radius:5px 5px 0}.casino-game-sidebar--is-over-game_zbZG1 .casino-game-sidebar__inner--has-bonus_Z23GE{border-bottom-right-radius:5px}html.app__os--windows .casino-game-sidebar__content_i3RXp::-webkit-scrollbar{display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-game-sidebar": `casino-game-sidebar_zhb7x`,
	"casino-game-sidebar__inner": `casino-game-sidebar__inner_fX3S8`,
	"casino-game-sidebar__header": `casino-game-sidebar__header_HCdwM`,
	"casino-game-sidebar__header--is-over-game": `casino-game-sidebar__header--is-over-game_IztoR`,
	"casino-game-sidebar__tabs": `casino-game-sidebar__tabs_SFSgt`,
	"casino-game-sidebar__tabs--multi": `casino-game-sidebar__tabs--multi_vyCpt`,
	"casino-game-sidebar__content": `casino-game-sidebar__content_i3RXp`,
	"casino-game-sidebar__content-scroll": `casino-game-sidebar__content-scroll__usR8`,
	"casino-game-sidebar__content-inner": `casino-game-sidebar__content-inner_v1keJ`,
	"casino-game-sidebar__current-level": `casino-game-sidebar__current-level_dcWAQ`,
	"casino-game-sidebar__shop-heading": `casino-game-sidebar__shop-heading_tyxDz`,
	"casino-game-sidebar__shop-wrapper": `casino-game-sidebar__shop-wrapper_XGP7W`,
	"casino-game-sidebar--is-over-game": `casino-game-sidebar--is-over-game_zbZG1`,
	"casino-game-sidebar__inner--has-bonus": `casino-game-sidebar__inner--has-bonus_Z23GE`
};
export default ___CSS_LOADER_EXPORT___;
