// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-sidebar-navigation-block_TtuQw{display:flex;flex-direction:column;gap:4px;padding:4px;background-color:var(--OpacityLayer1);border-radius:var(--BorderRadius)}.casino-sidebar-navigation-block__item_QToYD{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;display:flex;gap:16px;align-items:center;justify-content:flex-start;width:100%;height:44px;padding:0 16px;color:var(--TextPrimary);-webkit-text-decoration:none;text-decoration:none;cursor:pointer;border-radius:5px}.casino-sidebar-navigation-block__item_QToYD:hover{color:var(--TextDefault);background-color:var(--Highlight);cursor:pointer}.casino-sidebar-navigation-block__item_QToYD:hover .casino-sidebar-navigation-block__image_zunhW{filter:var(--EgsCategoriesIconHover)}.casino-sidebar-navigation-block__item--active_v9fTn,.casino-sidebar-navigation-block__item--active_v9fTn:hover{color:var(--Layer0);background-color:var(--TextDefault)}.casino-sidebar-navigation-block__image_zunhW{filter:var(--EgsCategoriesIconFilter)}.casino-sidebar-navigation-block__item--active_v9fTn .casino-sidebar-navigation-block__image_zunhW,.casino-sidebar-navigation-block__item--active_v9fTn:hover .casino-sidebar-navigation-block__image_zunhW{filter:var(--EgsCategoriesIconActive)}.casino-sidebar-navigation-block__loading-item_ESfYw{display:flex;gap:16px;align-items:center;justify-content:flex-start;width:100%;height:44px;padding:0 16px}.casino-sidebar-navigation-block__loading-icon_frpE9{width:20px;height:20px;background-color:var(--Layer2);border-radius:50%}.casino-sidebar-navigation-block__loading-text_eUEJ1{width:60px;height:8px;background-color:var(--Layer2);border-radius:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-sidebar-navigation-block": `casino-sidebar-navigation-block_TtuQw`,
	"casino-sidebar-navigation-block__item": `casino-sidebar-navigation-block__item_QToYD`,
	"casino-sidebar-navigation-block__image": `casino-sidebar-navigation-block__image_zunhW`,
	"casino-sidebar-navigation-block__item--active": `casino-sidebar-navigation-block__item--active_v9fTn`,
	"casino-sidebar-navigation-block__loading-item": `casino-sidebar-navigation-block__loading-item_ESfYw`,
	"casino-sidebar-navigation-block__loading-icon": `casino-sidebar-navigation-block__loading-icon_frpE9`,
	"casino-sidebar-navigation-block__loading-text": `casino-sidebar-navigation-block__loading-text_eUEJ1`
};
export default ___CSS_LOADER_EXPORT___;
