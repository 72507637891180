// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-category-tabs__image_rBEoa{filter:var(--EgsCategoriesIconFilter);width:20px;height:20px}.casino-category-tabs__item_nH17p:hover .casino-category-tabs__image_rBEoa{filter:var(--EgsCategoriesIconHover)}.casino-category-tabs__image--active_mDMh7,.casino-category-tabs__item_nH17p:hover .casino-category-tabs__image--active_mDMh7{filter:var(--EgsCategoriesIconActive)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-category-tabs__image": `casino-category-tabs__image_rBEoa`,
	"casino-category-tabs__item": `casino-category-tabs__item_nH17p`,
	"casino-category-tabs__image--active": `casino-category-tabs__image--active_mDMh7`
};
export default ___CSS_LOADER_EXPORT___;
