// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-casino-slots-bar_UYOdm{display:flex;flex-direction:row;align-items:center;justify-content:space-between;padding:8px 16px;border-radius:0 0 5px 5px}.bonus-casino-slots-bar__bonus-title_ULQcj{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;margin-bottom:4px;overflow:hidden;color:var(--TextDefault);text-overflow:ellipsis;white-space:nowrap}.bonus-casino-slots-bar__informer-inline-wrapper_ZBjH2{display:inline-flex;align-items:center}.bonus-casino-slots-bar__balance-label_lSF_1{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal;margin-right:4px;color:var(--TextSecondary)}.bonus-casino-slots-bar__balance_G2DKP{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.bonus-casino-slots-bar__progress_lDrgR{margin-bottom:14px;white-space:nowrap}.bonus-casino-slots-bar__progress-line_dOwTx{width:300px}.bonus-casino-slots-bar__info_r01DP{display:flex;gap:8px;align-items:center;justify-content:space-between}.bonus-casino-slots-bar__info-date-text_a_zCB{line-height:12px}.bonus-casino-slots-bar__info-date-text_a_zCB,.bonus-casino-slots-bar__info-text_t8sfQ{font-size:12px;font-weight:400;letter-spacing:normal;color:var(--TextSecondary)}.bonus-casino-slots-bar__info-text_t8sfQ{line-height:16px}.bonus-casino-slots-bar__info-text--current_iEwaa{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextDefault)}.bonus-casino-slots-bar__info-text--divider_kGmAh{padding:0 3px}.bonus-casino-slots-bar__button_KpJo6{width:160px}.bonus-casino-slots-bar__info-icon__Asms{margin-left:4px;cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-casino-slots-bar": `bonus-casino-slots-bar_UYOdm`,
	"bonus-casino-slots-bar__bonus-title": `bonus-casino-slots-bar__bonus-title_ULQcj`,
	"bonus-casino-slots-bar__informer-inline-wrapper": `bonus-casino-slots-bar__informer-inline-wrapper_ZBjH2`,
	"bonus-casino-slots-bar__balance-label": `bonus-casino-slots-bar__balance-label_lSF_1`,
	"bonus-casino-slots-bar__balance": `bonus-casino-slots-bar__balance_G2DKP`,
	"bonus-casino-slots-bar__progress": `bonus-casino-slots-bar__progress_lDrgR`,
	"bonus-casino-slots-bar__progress-line": `bonus-casino-slots-bar__progress-line_dOwTx`,
	"bonus-casino-slots-bar__info": `bonus-casino-slots-bar__info_r01DP`,
	"bonus-casino-slots-bar__info-date-text": `bonus-casino-slots-bar__info-date-text_a_zCB`,
	"bonus-casino-slots-bar__info-text": `bonus-casino-slots-bar__info-text_t8sfQ`,
	"bonus-casino-slots-bar__info-text--current": `bonus-casino-slots-bar__info-text--current_iEwaa`,
	"bonus-casino-slots-bar__info-text--divider": `bonus-casino-slots-bar__info-text--divider_kGmAh`,
	"bonus-casino-slots-bar__button": `bonus-casino-slots-bar__button_KpJo6`,
	"bonus-casino-slots-bar__info-icon": `bonus-casino-slots-bar__info-icon__Asms`
};
export default ___CSS_LOADER_EXPORT___;
