// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .promotion-cashback-widget_A2MxH{position:relative;display:flex;align-items:center;justify-content:space-between;padding:8px 16px;background-color:#0000;border-radius:0 0 5px 5px}@media(hover:hover)and (pointer:fine){html.app__layout--desktop .promotion-cashback-widget_A2MxH:hover{cursor:pointer}}html.app__layout--desktop .promotion-cashback-widget__label_wvZ0T{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;margin:0 0 4px;color:var(--TextDefault)}html.app__layout--desktop .promotion-cashback-widget__amount_Z9HKk{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotion-cashback-widget": `promotion-cashback-widget_A2MxH`,
	"promotion-cashback-widget__label": `promotion-cashback-widget__label_wvZ0T`,
	"promotion-cashback-widget__amount": `promotion-cashback-widget__amount_Z9HKk`
};
export default ___CSS_LOADER_EXPORT___;
