// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-control-bar_nT7cI{display:grid;grid-template-columns:1fr min-content 1fr;grid-column-gap:16px;align-items:center;width:100%;min-width:750px;height:44px;padding:0 0 0 16px;color:var(--TextDefault);background-color:var(--EgsOverlayBarColor);border-radius:5px 5px 0 0}@media(min-width:700px)and (max-width:720px){.casino-control-bar_nT7cI{min-width:700px;max-width:700px}}.casino-control-bar__title_W2KVs{font-size:18px;font-weight:500;line-height:24px;letter-spacing:normal;display:flex;gap:8px;align-items:center;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.casino-control-bar__controls_vGS5s{display:flex;gap:0;align-items:center;justify-content:flex-end}.casino-control-bar__switch_KqjlA{margin-right:16px}.casino-control-bar__sidebar-switcher_SBCkN{margin:0 16px}.casino-control-bar--sidebar-opened_hNieE{border-top-right-radius:0}html.app__fullscreen .casino-control-bar_nT7cI{-webkit-backdrop-filter:blur(80px);backdrop-filter:blur(80px);border-radius:0}html.app__fullscreen .casino-control-bar__sidebar-switcher_SBCkN{display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-control-bar": `casino-control-bar_nT7cI`,
	"casino-control-bar__title": `casino-control-bar__title_W2KVs`,
	"casino-control-bar__controls": `casino-control-bar__controls_vGS5s`,
	"casino-control-bar__switch": `casino-control-bar__switch_KqjlA`,
	"casino-control-bar__sidebar-switcher": `casino-control-bar__sidebar-switcher_SBCkN`,
	"casino-control-bar--sidebar-opened": `casino-control-bar--sidebar-opened_hNieE`
};
export default ___CSS_LOADER_EXPORT___;
