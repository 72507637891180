// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-sidebar_nJo7o{display:flex;flex-direction:column;min-height:400px}.casino-sidebar__arrow-icon_jFq6G{color:var(--TextSecondary)}.casino-sidebar__filter_Ovp4Y{margin-bottom:8px}.casino-sidebar__search_x5Z9q{background:var(--Layer1);border-radius:5px}.casino-sidebar__search_x5Z9q:hover{background-color:var(--Highlight)}.casino-sidebar__help_G1djm{margin-top:32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-sidebar": `casino-sidebar_nJo7o`,
	"casino-sidebar__arrow-icon": `casino-sidebar__arrow-icon_jFq6G`,
	"casino-sidebar__filter": `casino-sidebar__filter_Ovp4Y`,
	"casino-sidebar__search": `casino-sidebar__search_x5Z9q`,
	"casino-sidebar__help": `casino-sidebar__help_G1djm`
};
export default ___CSS_LOADER_EXPORT___;
