// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-game-time_D_z59{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;padding:0;white-space:nowrap;background-color:none;border-radius:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-game-time": `casino-game-time_D_z59`
};
export default ___CSS_LOADER_EXPORT___;
