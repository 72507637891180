import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import RouteName from '@leon-hub/routing-config';
import LobbyEgsNoGames from 'web/src/modules/lobby/components/LobbyEgsNoGames/LobbyEgsNoGames.vue';
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import CasinoLobbyNavigation from 'web/src/modules/casino/submodules/lobby/components/CasinoLobbyNavigation/CasinoLobbyNavigation.vue';
import { useCategoriesTabs } from 'web/src/modules/casino/submodules/lobby/composables';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import CasinoLobbyBlockError from 'web/src/modules/casino/submodules/lobby/components/CasinoLobbyBlockError/CasinoLobbyBlockError.vue';
import { useCasinoLobbyPage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLobbyRoutePage',
    setup (__props) {
        const { isFavorite, hasGroup, groupName, isLoadingCategories, hasNoGames, isAllTab, groupId, groupUrl, categoryId, lobbyType, isLoadingLobby, skeletons, metaParameters, groupBlockType, removeFavoriteGames, onClickSearch, onClickGroup, onPlay } = useCasinoLobbyPage();
        const { categoriesTabs } = useCategoriesTabs();
        useSeoMetaParameters(metaParameters);
        useKeepAlive();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['casino-lobby'])
            }, [
                (_openBlock(), _createBlock(CasinoLobbyNavigation, {
                    key: 0,
                    "active-group-name": _unref(groupName),
                    "has-group-name": _unref(hasGroup),
                    "categories-tabs": _unref(categoriesTabs),
                    "is-categories-loading": _unref(isLoadingCategories),
                    class: _normalizeClass(_ctx.$style['casino-lobby__navigation']),
                    onClickSearch: _unref(onClickSearch),
                    onClickGroups: _unref(onClickGroup)
                }, null, 8, [
                    "active-group-name",
                    "has-group-name",
                    "categories-tabs",
                    "is-categories-loading",
                    "class",
                    "onClickSearch",
                    "onClickGroups"
                ])),
                _unref(groupBlockType) ? (_openBlock(), _createBlock(CasinoLobbyBlockError, {
                    key: 1,
                    "group-name": _unref(groupName),
                    class: _normalizeClass(_ctx.$style['casino-lobby__block-error'])
                }, null, 8, [
                    "group-name",
                    "class"
                ])) : _unref(hasNoGames) ? (_openBlock(), _createBlock(LobbyEgsNoGames, {
                    key: 2,
                    "is-button-hidden": _ctx.$route.name === _unref(RouteName).HOME
                }, null, 8, [
                    "is-button-hidden"
                ])) : _createCommentVNode("", true),
                (_openBlock(), _createBlock(_unref(LobbyBlock), {
                    key: _unref(lobbyType),
                    "lobby-type": _unref(lobbyType),
                    "category-id": _unref(categoryId),
                    "prevent-items-fetch": "",
                    "is-filters-allowed": !_unref(isFavorite),
                    "is-all-tab": _unref(isAllTab),
                    "group-id": _unref(groupId),
                    "group-url": _unref(groupUrl),
                    skeletons: _unref(skeletons),
                    "no-background": false,
                    "group-block": _unref(groupBlockType),
                    "is-full-height": "",
                    onGameClick: _unref(onPlay),
                    onDeleteItems: _unref(removeFavoriteGames)
                }, null, 8, [
                    "lobby-type",
                    "category-id",
                    "is-filters-allowed",
                    "is-all-tab",
                    "group-id",
                    "group-url",
                    "skeletons",
                    "no-background",
                    "group-block",
                    "onGameClick",
                    "onDeleteItems"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLobbyRoutePage'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'casino-lobby-page',
                        loading: _unref(isLoadingLobby)
                    }
                ]
            ]);
        };
    }
});
