// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .casino-lobby-navigation_jB2tK{display:flex;flex-direction:row}@media(min-width:1150px){html.app__layout--desktop .casino-lobby-navigation_jB2tK{display:none}}html.app__layout--desktop .casino-lobby-navigation__group_PayYU{display:flex;margin:0}html.app__layout--desktop .casino-lobby-navigation__provider_tVFAd{margin:0 16px 0 8px}html.app__layout--desktop .casino-lobby-navigation__search_bWZFJ{width:52px;height:52px;background-color:var(--Layer1)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-lobby-navigation": `casino-lobby-navigation_jB2tK`,
	"casino-lobby-navigation__group": `casino-lobby-navigation__group_PayYU`,
	"casino-lobby-navigation__provider": `casino-lobby-navigation__provider_tVFAd`,
	"casino-lobby-navigation__search": `casino-lobby-navigation__search_bWZFJ`
};
export default ___CSS_LOADER_EXPORT___;
