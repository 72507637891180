import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VScrollbar } from '@components/v-scrollbar';
import CasinoLoyaltyProgramCurrentLevelCard from // eslint-disable-next-line max-len
'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramCurrentLevelCard/CasinoLoyaltyProgramCurrentLevelCard.vue';
import CasinoLoyaltyProgramSwiper from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramSwiper/CasinoLoyaltyProgramSwiper.vue';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import BonusLoyaltyProgramPanel from 'web/src/modules/bonuses/components/BonusLoyaltyProgramPanel/BonusLoyaltyProgramPanel.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { CasinoLoyaltyProgramCardSizes } from 'web/src/modules/casino-loyalty-program/store/types';
import BonusHowDoesItWork from 'web/src/modules/bonuses/components/BonusHowDoesItWork/BonusHowDoesItWork.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import CasinoGameSideBarAnonymous from 'web/src/modules/casino/submodules/game/components/CasinoGameSideBarAnonymous/CasinoGameSideBarAnonymous.vue';
import BonusLoyaltyProgramCard from 'web/src/modules/bonuses/components/BonusLoyaltyProgramCard/BonusLoyaltyProgramCard.vue';
import { useCasinoGameSideBarContainer } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameSideBarContainer',
    props: {
        isOverGame: {
            type: Boolean
        },
        isHideMyBonuses: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { isLoyaltySidebarOpened, bonus, isLoggedIn, tabsProperties, isBonusLoaded, isMultiTab, isLoyaltyProgramTab, currentLevelCardProperties, levelSettingsWithStatus, currentPoints, amountNumber, remainingAmountNumber, progress, progressPercentString, isParticipating, isLeonShopEnabled, offers, isShopOffersLoaded, handleExchange, handleParticipate, toggleSidebarOpened, onTabClick, showCurrentLevelDescription, showLevelDescription, onShopItemClick } = useCasinoGameSideBarContainer(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isLoyaltySidebarOpened) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['casino-game-sidebar']]: true,
                    [_ctx.$style['casino-game-sidebar--is-over-game']]: _ctx.isOverGame
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['casino-game-sidebar__inner']]: true,
                        [_ctx.$style['casino-game-sidebar__inner--has-bonus']]: !!_unref(bonus)
                    })
                }, [
                    _unref(isLoggedIn) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['casino-game-sidebar__header']]: true,
                                [_ctx.$style['casino-game-sidebar__header--is-over-game']]: _ctx.isOverGame
                            })
                        }, [
                            _createVNode(VTabs, _mergeProps(_unref(tabsProperties), {
                                class: {
                                    [_ctx.$style['casino-game-sidebar__tabs']]: true,
                                    [_ctx.$style['casino-game-sidebar__tabs--multi']]: _unref(isMultiTab)
                                },
                                single: !_unref(isMultiTab),
                                onTabClick: _unref(onTabClick)
                            }), null, 16, [
                                "class",
                                "single",
                                "onTabClick"
                            ]),
                            _ctx.isOverGame ? (_openBlock(), _createBlock(VButton, {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['casino-game-sidebar__button-close']),
                                "icon-name": _unref(IconName).CROSS,
                                "icon-size": _unref(IconSize).SIZE_24,
                                kind: _unref(ButtonKind).TRANSPARENT,
                                onClick: _unref(toggleSidebarOpened)
                            }, null, 8, [
                                "class",
                                "icon-name",
                                "icon-size",
                                "kind",
                                "onClick"
                            ])) : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['casino-game-sidebar__content'])
                        }, [
                            _unref(isLoyaltyProgramTab) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['casino-game-sidebar__content-inner'])
                            }, [
                                _createVNode(CasinoLoyaltyProgramCurrentLevelCard, _mergeProps(_unref(currentLevelCardProperties), {
                                    class: _ctx.$style['casino-game-sidebar__current-level'],
                                    onShowDescription: _unref(showCurrentLevelDescription)
                                }), null, 16, [
                                    "class",
                                    "onShowDescription"
                                ]),
                                _createVNode(CasinoLoyaltyProgramSwiper, {
                                    cards: _unref(levelSettingsWithStatus),
                                    "current-points": _unref(currentPoints),
                                    "card-size": _unref(CasinoLoyaltyProgramCardSizes).SIZE_116,
                                    "has-navigation": "",
                                    "no-padding": "",
                                    "no-header-title-padding": "",
                                    class: _normalizeClass(_ctx.$style['casino-game-sidebar__progress-swiper']),
                                    onClickCard: _unref(showLevelDescription)
                                }, null, 8, [
                                    "cards",
                                    "current-points",
                                    "card-size",
                                    "class",
                                    "onClickCard"
                                ])
                            ], 2)) : _ctx.isHideMyBonuses ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(VScrollbar), {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['casino-game-sidebar__content-scroll']),
                                "flex-fill": ""
                            }, {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['casino-game-sidebar__content-inner'])
                                        }, [
                                            _unref(isBonusLoaded) ? (_openBlock(), _createElementBlock(_Fragment, {
                                                key: 1
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['casino-game-sidebar__shop-heading'])
                                                }, _toDisplayString(_ctx.$t('WEB2_EXCHANGE_FOR_MONEY')), 3),
                                                _createVNode(BonusLoyaltyProgramPanel, {
                                                    "amount-number": _unref(amountNumber),
                                                    progress: _unref(progress),
                                                    "progress-text": _unref(progressPercentString),
                                                    "remaining-amount-number": _unref(remainingAmountNumber),
                                                    "is-participating": _unref(isParticipating),
                                                    onWithdraw: _unref(handleExchange),
                                                    onParticipate: _unref(handleParticipate)
                                                }, null, 8, [
                                                    "amount-number",
                                                    "progress",
                                                    "progress-text",
                                                    "remaining-amount-number",
                                                    "is-participating",
                                                    "onWithdraw",
                                                    "onParticipate"
                                                ]),
                                                _unref(isLeonShopEnabled) ? (_openBlock(), _createElementBlock(_Fragment, {
                                                    key: 1
                                                }, [
                                                    _createElementVNode("div", {
                                                        class: _normalizeClass(_ctx.$style['casino-game-sidebar__shop-heading'])
                                                    }, _toDisplayString(_ctx.$t('WEB2_EXCHANGE_FOR_BONUSES')), 3),
                                                    _unref(isShopOffersLoaded) ? (_openBlock(), _createElementBlock("div", {
                                                        key: 1,
                                                        class: _normalizeClass(_ctx.$style['casino-game-sidebar__shop-wrapper'])
                                                    }, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(offers), (item)=>(_openBlock(), _createBlock(BonusLoyaltyProgramCard, {
                                                                key: item.id,
                                                                name: item.name,
                                                                balance: item.price,
                                                                "formatted-balance": item.formattedPrice,
                                                                "image-src": item.imageUrl,
                                                                "is-locked": item.price > _unref(amountNumber),
                                                                onClick: ($event)=>_unref(onShopItemClick)(item.id)
                                                            }, null, 8, [
                                                                "name",
                                                                "balance",
                                                                "formatted-balance",
                                                                "image-src",
                                                                "is-locked",
                                                                "onClick"
                                                            ]))), 128))
                                                    ], 2)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                                                        key: 0
                                                    }))
                                                ], 64)) : (_openBlock(), _createBlock(BonusHowDoesItWork, {
                                                    key: 0
                                                }))
                                            ], 64)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                                                key: 0
                                            }))
                                        ], 2)
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ]))
                        ], 2)
                    ], 64)) : (_openBlock(), _createBlock(CasinoGameSideBarAnonymous, {
                        key: 0,
                        "is-over-game": _ctx.isOverGame,
                        onClickClose: _unref(toggleSidebarOpened)
                    }, null, 8, [
                        "is-over-game",
                        "onClickClose"
                    ]))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameSideBarContainer'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
